<template>
  <img
    :src="appLogoImage"
    alt="logo"
    class=""
    width="150px"
  >
</template>

<script>
export default {
  data() {
    return {
      appLogoImage: '',
    }
  },
  mounted() {
    this.appLogoImage = process.env.VUE_APP_BACK_URL + JSON.parse(localStorage.getItem('settings')).app_logo
  },
}
</script>
