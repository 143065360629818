<template>
  <div>
    <barcode
      v-if="value"
      :font-size="fontSize"
      :width="width"
      :height="height"
      :value="value"
    >
      Failed to load barcode
    </barcode>
  </div>
</template>
<script>
import VueBarcode from 'vue-barcode'

export default {
  name: 'ShipmentBarCodeVue',
  components: {
    barcode: VueBarcode,
  },
  props: [
    // eslint-disable-next-line vue/require-prop-types
    'value',
    // eslint-disable-next-line vue/require-prop-types
    'width',
    // eslint-disable-next-line vue/require-prop-types
    'height',
    // eslint-disable-next-line vue/require-prop-types
    'fontSize',
  ],
  data() {
    return {}
  },
  created() {
    if (!this.height) {
      this.height = 50
      this.fontSize = 26
    }
  },
}
</script>
